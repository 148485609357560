import './locationDetail.css'
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from "moment";
import { setTab, submitQueryForm } from "../../actions/mainAction";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";

const LocationDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tab } = useSelector(state => state.main)
    const [boxSelected, setBoxSelected] = useState(false)
    const { detail } = tab
    const themePallet = useSelector(state => state.main.theme)
    const [formSubmission, setFormSubmission] = useState(false)
    const [form, setForm] = useState([
        {
            name: "firstName",
            placeholder: "*First Name",
            value: "",
            type: "text",
            required: true
        },
        {
            name: "lastName",
            placeholder: "*Last Name",
            value: "",
            type: "text",
            required: true
        },
        {
            name: "email",
            placeholder: "*Email",
            value: "",
            type: "email",
            required: true
        },
        {
            name: "message",
            placeholder: "Message",
            value: "",
            type: "textarea",
            required: false
        }
    ])
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    let dayNumber = 0
    React.useEffect(() => {
        if (detail) {
            dayNumber = moment().day()
        }
    }, [detail])

    const handleBackIcon = () => {
        if(new URL(window.location.href).searchParams.get('storeName')){
            navigate("/");
        }
        if(new URL(window.location.href).searchParams.get('productName')){
            navigate("/");
        }

        dispatch(setTab({ id: null, detail: null }))
    }

    const handleCheckBox = () => {
        setBoxSelected(!boxSelected)
    }

    const handleChangeInput = (e) => {
        let { name, value } = e.target
        let updatedForm = form.map(item => {
            if (item.name === name)
                item.value = value
            return item
        })
        setForm(updatedForm)
    }

    const onSubmit = () => {
        let payload = {}
        let body = {}
        form.forEach(form => {
            if (form.name === "email")
                payload.ToEmail = "info@neobi.io"
            if (form.name === "firstName")
                body.firstName = form.value
            if (form.name === "lastName")
                body.lastName = form.value
            if (form.name === "message")
                body.message = form.value
        })
        // Add the URL sender
        body.urlSender = window.location.href;

        // Add the location name
        if (detail?.name) {
            body.locationName = detail.name;
        }

        body.keepMePosted = boxSelected
        payload.Body = JSON.stringify(body)
        payload.Subject = "Out of Stock Query"
        dispatch(submitQueryForm(payload))
        setFormSubmission(true)
    }

    if (detail) {
        return (
            <div className="location-detail">
                <div className="detail-header" style={{ color: themePallet.darkFont }}>
                    <div className="location-name">
                        <span className="back-icon" onClick={handleBackIcon}><ArrowBackIosIcon
                            style={{ color: themePallet.active, width: "15px", height: "15px" }} /></span>
                        <Tooltip
                            title={detail.name}
                            placement="top"
                            arrow
                        >
                            <span className="name">{detail.name}</span>
                        </Tooltip>
                    </div>
                    <div className="distance">
                        {(parseFloat(detail.distance) / 1000)?.toFixed(1)} KM
                    </div>
                </div>
                <div className="location-address" style={{ color: themePallet.darkFont }}>
                    <div className="location-icon">
                        <LocationOnIcon sx={{ mt: "-5px" }} />
                    </div>
                    <div className="address" style={{ color: themePallet.lightFont }}>
                        <Tooltip
                            title={detail.address + ", " + detail?.city + ", " + detail.province}
                            placement="top"
                            arrow
                        >
                            <span>{detail.address + ", " + detail?.city + ", " + detail.province}</span>
                        </Tooltip>
                    </div>
                </div>
                {
                    <>
                        {detail.stockStatus === "In Stock" && <div className="product-title" style={{ color: themePallet.darkFont }}>Products</div>}
                        {detail.stockStatus === "In Stock" &&
                            <div className="products-section" style={{ color: themePallet.lightFont }}>
                                {
                                    detail.products.map((product, index) => {
                                        return (
                                            <div key={index} className="product-row">
                                                <div className="name main-name">
                                                    <Tooltip
                                                        title={product.name}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <span className="name">{product.name}</span>
                                                    </Tooltip>
                                                </div>
                                                <div className="status" style={{ color: themePallet.active }}>{product.instockstatus}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>}
                    </>
                }
                {
                    <>
                        {detail.stockStatus === "Out of Stock" && <div className="stock-query-title" style={{ color: themePallet.active }}>Out of Stock</div>}
                        {detail.stockStatus === "Out of Stock" &&
                            <div className="stock-query-desc" style={{ color: themePallet.darkFont }}>This store is currently out of our products.</div>}
                        {detail.stockStatus === "Out of Stock" &&
                            <div className="stock-query-desc" style={{ color: themePallet.lightFont }}>Submit a request below for this store to
                                start </div>}
                        {detail.stockStatus === "Out of Stock" && <div className="stock-query-desc" style={{ color: themePallet.lightFont }}>carrying our
                            products. </div>}
                        {!formSubmission && detail.stockStatus === "Out of Stock" &&
                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                {
                                    form.map((item, index) => {
                                        if (item.type !== "textarea") {
                                            return (
                                                <div className="control" key={item.name + index}>
                                                    <input type={item.type}
                                                        name={item.name}
                                                        required={item.required}
                                                        placeholder={item.placeholder}
                                                        style={{ background: themePallet.darkBackground, border: "2px solid " + themePallet.darkBackground }}
                                                        value={item.value}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="control">
                                                    <textarea name={item.name}
                                                        required={item.required}
                                                        placeholder={item.placeholder} rows="5"
                                                        style={{ background: themePallet.darkBackground, border: "2px solid " + themePallet.darkBackground, color: themePallet.darkFont + " !important" }}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {detail.stockStatus === "Out of Stock" &&
                                    <div className="submission">
                                        <div className="control">
                                            <button className="submit-button" style={{ background: themePallet.active, color: themePallet.darkBackground }}>Send</button>
                                        </div>
                                        <div className="keep-updated">
                                            <span className="stock-query-checkbox" style={{ border: "1px solid " + themePallet.darkFont }} onClick={handleCheckBox}>
                                            </span>
                                            {boxSelected ? <span className="box" onClick={handleCheckBox} /> : <span className="empty" />}
                                            <label htmlFor="keep-updated" style={{ color: themePallet.lightFont }}>Keep me posted.</label>
                                        </div>
                                    </div>
                                }
                            </form>}
                        {formSubmission && detail.stockStatus === "Out of Stock" && <p className="feedback-message">Thanks for your feedback!</p>}
                    </>
                }
            </div>
        )
    }
    else {
        return (
            <div>No Location Found.</div>
        )
    }
}

export default LocationDetail;