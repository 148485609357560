import React, { useEffect, useState } from "react";
import "../App.css";
import "leaflet/dist/leaflet.css";
import Map from "../Components/Map/Map";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import NavBar from "./NavBar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updateTheme } from "../actions/mainAction";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

function MapPage() {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const location = useLocation();
    // function useQuery() {
    //     return new URLSearchParams(location.search);
    // }

    //New case insensitive query string parm
    function useQuery() {
        const params = new URLSearchParams(location.search);
        const queryObject = {};
        for (const [key, value] of params.entries()) {
            queryObject[key.toLowerCase()] = value; // Normalize to lowercase
        }
        return queryObject;
    }
    const query = useQuery();
    //const customerKey = query.get("customerkey");
    const customerKey = query["customerkey"];
    
    const themePallet = useSelector(state => state.main.theme)
    const localValue = {
        darkBackground: localStorage.getItem('REACT_APP_DARK_BACKGROUND'),
        darkFont: localStorage.getItem('REACT_APP_DARK_FONT'),
        modeClass: localStorage.getItem('REACT_APP_MODE_CLASS')
        // Add more items as needed
    };
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    document.addEventListener('gesturestart', function (e) {
        e.preventDefault();
    });

    return (
        <div className="App" style={{ border: "4px solid " + themePallet.darkBackground, borderBottom: "6px solid " + themePallet.darkBackground }}>
            <NavBar />
            <Map onOpenModal={onOpenModal} onCloseModal={onCloseModal} />
            <Modal open={open} onClose={onCloseModal} center classNames={{ modal: localValue.modeClass }}>
                <h2 className="modal-heading">
                    Location Disabled <ErrorOutlineIcon sx={{ color: localValue.darkFont + " !important" }} />{" "}
                </h2>
                <p className="location-request">
                    To get directions, please enable location services on your device.
                </p>
                <button className="btn-ok" onClick={onCloseModal} style={{ background: localValue.darkBackground, color: localValue.darkFont, border: "1px solid " + localValue.darkFont }}>
                    Ok
                </button>
            </Modal>
        </div>
    );
}

export default MapPage;
