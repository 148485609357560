import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { MapConsumer, MapContainer, Marker, Popup, TileLayer, useMapEvents, } from "react-leaflet";
import SliderForZoom from "../SliderForZoom/SliderForZoom";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSingleLocationData, setFilterResults } from "../../actions/filterAction";
import { MENU_CONTENT } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setAction, getApiProducts, setApiResponse, setTab, setUserLocation, getMapDetails, setLoader, updateTheme, setApiProducts, setMapDetails } from "../../actions/mainAction";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { useLocation, useParams } from "react-router-dom";

const override = css`
  position: absolute;
  z-index: 9999;
  top: 50%;
`;

function Map({ onOpenModal }) {
  const dispatch = useDispatch()
  const popupRef = useRef([])
  const [mapDragging, setMapDragging] = useState(true);
  const [data, setData] = useState([]);
  const [filterAddress, setFilterAddress] = useState([]);
  const [map, setMap] = useState(null);
  let [zoom, setZoomLevel] = useState(13);
  const [products, setProducts] = useState([]);
  const [position, setPosition] = useState(null);
  const [kmClicked, setKmClicked] = useState(true);
  const [previousAddresses, setPreviousAddresses] = useState([]);
  const [selectProduct, setSelectProduct] = useState(false);
  const [defaultCenter, setDefaultCenter] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const center = [51.05011, -114.08529];
  const locationsRef = useRef([]);
  const [filterCities, setFilterCities] = useState([]);
  const { filter, filterResults } = useSelector(state => state.filters)
  const { tab, action, loader, currentLocation } = useSelector(state => state.main)
  const [localLoader, setLocalLoader] = useState(false);
  const params = useParams();
  let location = useLocation();
  let brand = localStorage?.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_BRAND : 'soar';

//   function useQuery() {
//     return new URLSearchParams(location.search);
// }
function useQuery() {
  const params = new URLSearchParams(location.search);
  const queryObject = {};
  for (const [key, value] of params.entries()) {
      queryObject[key.toLowerCase()] = value; // Normalize to lowercase
  }
  return queryObject;
}

const query = useQuery();
// const customerKey = query.get("customerkey");
// const productName = query.get("productname")
const customerKey = query["customerkey"];
const productName = query["productname"]

  const handleLocalStorageUpdate = () =>  {
    const isKeyExist = localStorage.getItem('customerKey');
    
    const isKeySame = isKeyExist === customerKey;

    if (!isKeySame ){
      localStorage.clear();
      localStorage.setItem('customerKey', customerKey);
    }
    return isKeySame;
  }

  const getMapSettings = async (customerKey) => {
    dispatch(setLoader(true))
    try {
        dispatch(updateTheme({
          lightBackground: "#ffffff",
        }));
        const res = await axios.get(`${process.env.REACT_APP_API}MapSettings/GetMapSettings?customerKey=${customerKey}`, {
            headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
        })
      return res.data;
            
    }
    catch (e) {
        console.log(e)
    }
  }

  // const getBrandProducts = async (brand) => {
  //   dispatch(setLoader(true))

  //   try {
      
  //     const res = await axios.get(`${process.env.REACT_APP_API}MapProducts?brand=${brand}`, {
  //         headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
  //     })
  //     dispatch(setApiProducts(res.data))
  // }
  // catch (e) {
  //     console.log(e)
  // }
  // }

  // const getMapDetails = async (brand) => {
  //   dispatch(setLoader(true))

  //   try {
      
  //     const res = await axios.get(`${process.env.REACT_APP_API}mapDetails?brand=${brand}`, {
  //         headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
  //     })
  //     dispatch(setMapDetails(res.data));
          
  // }
  // catch (e) {
  //     console.log(e)
  // }
  // }

  const normalizeBrand = (brand) => {
      // Normalize the brand by trimming spaces and removing extra whitespace
      return brand.split(',').map(b => b.trim()).join(',');
  };

  const getBrandProducts = async (brand) => {
      dispatch(setLoader(true));

      try {
          // Normalize brand before making the request
          const normalizedBrand = normalizeBrand(brand);

          const res = await axios.get(`${process.env.REACT_APP_API}MapProducts?brand=${normalizedBrand}`, {
              headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
          });
          dispatch(setApiProducts(res.data));
      } catch (e) {
          console.log(e);
      }
  };

  const getMapDetails = async (brand) => {
      dispatch(setLoader(true));

      try {
          // Normalize brand before making the request
          const normalizedBrand = normalizeBrand(brand);

          const res = await axios.get(`${process.env.REACT_APP_API}mapDetails?brand=${normalizedBrand}`, {
              headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
          });
          dispatch(setMapDetails(res.data));
      } catch (e) {
          console.log(e);
      }
  };

  const fetchInitialData = async () => {
    const isKeySame = handleLocalStorageUpdate();
    console.log("IS KEY SAME", isKeySame)
    var mapSettings = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')) : '';
    try {
      if( !isKeySame ){
        mapSettings = await getMapSettings(customerKey);
        if (mapSettings) {
          localStorage.setItem('MapSettings', JSON.stringify(mapSettings));
        } else {
          notify()
        }
      }
      brand = mapSettings.REACT_APP_BRAND;
      dispatch(updateTheme({
              darkBackground: mapSettings.REACT_APP_DARK_BACKGROUND,
              lightBackground: mapSettings.REACT_APP_LIGHT_BACKGROUND,
              darkFont: mapSettings.REACT_APP_DARK_FONT,
              lightFont: mapSettings.REACT_APP_LIGHT_FONT,
              inStockColor: mapSettings.REACT_APP_IN_STOCK_COLOR,
              stockColor: mapSettings.REACT_APP_STOCK_COLOR,
              active: mapSettings.REACT_APP_ACTIVE
            }))
      await getMapDetails(mapSettings.REACT_APP_BRAND);
      await getBrandProducts(mapSettings.REACT_APP_BRAND);
      getLocationData();
    } catch (error) {
      console.log("ERROR", error)
    }
  }

 

  useEffect(() => { 
    if (!productName) {
      fetchInitialData();
    }
  }, [customerKey]);
 

  useEffect(() => {
    if (tab.id === "location-detail" && tab.detail) {
      fitBounds(tab?.detail)
      setTimeout(() => {
        if (tab?.detail?.p_id)
          popupRef.current[tab.detail.p_id]?.openOn(map)
      }, [50])
    }
    if (!tab?.id) {
      map?.closePopup();
    }
    setLocalLoader(false);
  }, [tab])

  useEffect(() => {
    if (action.status === "near-me" && action.item === "near-me") {
      navigateToUserLocation(false)
      dispatch(setAction({ status: null, item: null }));
    }
  }, [action])

  useEffect(() => {
    let tempCity = data.filter((lc) => {
      let fCity = filterCities.filter((tCity) => {
        return (
          tCity.localeCompare(lc.city, undefined, { sensitivity: "base" }) === 0
        );
      });
      return fCity.length > 0;
    });
    if (filterCities.length > 0) {
      sortLocationOnDistance(tempCity);
    } else {
      sortLocationOnDistance(data);
    }
  }, [filterCities]);

  useEffect(() => {
    locationsRef.current = locationsRef.current.slice(0, data.length);
  }, [data]);

  useEffect(() => {
    dispatch(setLoader(true))
    setFilterAddress(filterResults)
    let markers = []
    {filterResults && filterResults?.forEach(item => {
      markers.push(L.marker([item.latitude, item.longtitude]))
    })}
    let markersGroup = new L.featureGroup(markers)
    if (markersGroup.getBounds().isValid()) {
      map.fitBounds(
        markersGroup.getBounds(),
        isMobile === true ? { paddingTopLeft: [200, 0] } : { padding: [20, 20] }
      );
      map.setMaxZoom(18);
    }
    dispatch(setAction({ status: null, item: null }));
    dispatch(setLoader(false))
  }, [filterResults])

  useEffect(() => {
    if (map) {
      navigateToUserLocation()
      if (isMobile)
        map.on('click', (e) => {
          dispatch(setTab({ id: "close-panel", detail: "close-panel" }))
        })
    }
  }, [map])

  const getLocationData = (loc = null) => {
    if (brand) {
        dispatch(setLoader(true));
        try {
            const brand = localStorage.getItem('MapSettings') 
                ? normalizeBrand(JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_BRAND) 
                : 'soar';

            axios
                .get(
                    `${process.env.REACT_APP_API}MapLocations/GetAllLocations?brand=${brand}&longlat=${loc?.lng ?? 0},${loc?.lat ?? 0}`,
                    {
                        headers: {
                            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY,
                            Accept: "application/json, text/plain, */*",
                        },
                    }
                )
                .then((res) => {
                    if (res?.data) {
                        setData(res?.data);
                        sortLocationOnDistance(res?.data);
                        dispatch(setApiResponse(res?.data));
                        dispatch(setFilterResults(res?.data));
                    } else {
                        notify();
                    }
                    dispatch(setLoader(false));
                })
                .catch((error) => {
                    notify();
                    dispatch(setLoader(false));
                });
        } catch (e) {
            notify();
            dispatch(setLoader(false));
        }
    }
};


  useEffect(() => {
    // resolve the marker display issue
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);
  const appOutOfStock = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_OUT_OF_STOCK : '';
  const appCurrentLocation = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_CURRENT_LOCATION : '';


  // return location stlyes for clustered feature with count on center of circle
  const createClusterCustomIcon = function (cluster) {
    let mrkr = cluster.getAllChildMarkers();
    let clusterClass = "default-height"

    if (cluster.getChildCount() < 10)
      clusterClass = "single-figure"
    else if (cluster.getChildCount() >= 10 && cluster.getChildCount() < 100)
      clusterClass = "double-figure"
    else if (cluster.getChildCount() >= 100 && cluster.getChildCount() < 1000)
      clusterClass = "triple-figure"
    else if (cluster.getChildCount() >= 1000)
      clusterClass = "four-figure"

    let productList = [...mrkr[0].options.data.products];
    let temp = [];
    mrkr?.forEach((mr) => {
      if (
        checkAvailablilityHelper(mr.options.data.pos, productList) === false
      ) {
        temp.push(mr.options.data.pos);
      }
    });
    return L.divIcon({
      html: `<span class="${clusterClass}" style="background-color: ${JSON.parse(localStorage.getItem('MapSettings'))?.REACT_APP_ACTIVE};">${cluster.getChildCount()}</span>`,
      className: "marker-cluster-custom" + " " + clusterClass ,
      iconSize: L.point(60, 60, true),
    });
  };
  // return location stlyes with empty centered circle
  const createCustomIcon = L.divIcon({
    html: `<span style="background-color: ${JSON.parse(localStorage.getItem('MapSettings'))?.REACT_APP_ACTIVE}; height:25px"></span>`,
    className: "marker-cluster-custom ",
    iconSize: [25, 25],
    iconAnchor: [3, 15],
    popupAnchor: [29, -15],
  });
  const createCustomIconPurple = L.divIcon({
    html: `<span></span>`,
    className: "marker-cluster-custom purple",
    iconSize: new L.Point([60, 60]),
    // iconAnchor: [10, 41],
    popupAnchor: [30, 0],
  });
  const selectedIconGrey = L.divIcon({
    html: `<span></span>`,
    className: "selected-marker purple" + appOutOfStock,
    iconSize: new L.Point([60, 60]),
    // iconAnchor: [10, 41],
    popupAnchor: [30, 0],
  });

  const selectedIconGreen = L.divIcon({
    html: `<span></span>`,
    className: "selected-marker purple " + appOutOfStock,
    iconSize: new L.Point([60, 60]),
    // iconAnchor: [10, 41],
    popupAnchor: [30, 0],
  })
  // return location stlyes along with black centered circle
  const createCustomIconDisabled = L.divIcon({
    html: `<span></span>`,
    className: "marker-cluster-custom " + appOutOfStock,
    iconSize: [25, 25],
    iconAnchor: [3, 15],
  });

  const CurrentLocationIcon = L.divIcon({
    html: `<span></span>`,
    className: appCurrentLocation,
    iconSize: [50, 50],
  });

  useEffect(() => {
    sortLocationOnDistance(data, "LocationFilterUpdate");
    setPreviousAddresses(data);
  }, [position, data, kmClicked]);

  const dataHandler = () => {
    setKmClicked(false);
  };

  const fitBounds = (lc, id) => {
    id === null ? setSelectedLocation(lc) : setSelectedLocation({});
    const latitude = +lc.latitude;
    const longtitude = +lc.longtitude
    let group = L?.featureGroup(lc?.type && lc?.type === 'click' ? L?.marker([latitude, longtitude]) : [L.marker([latitude, longtitude])]);
    if (group.getBounds().isValid()) {
      map?.flyTo(
        [latitude, longtitude], map.getZoom() >= 16 ? map.getZoom() : 16, { ...(isMobile === true ? { paddingTopLeft: [200, 0] } : { padding: [20, 20] }) }
      );
      map?.setMaxZoom(18);
    }
  };
  const setMapZoom = (event, newZoom) => {
    if (zoom != newZoom) {
      setZoomLevel(newZoom);
      map.setZoom(newZoom);
    }
  };
  const plusMinusZoom = (trigerIcon) => {
    if (trigerIcon === "minus") {
      if (zoom > 1) {
        setZoomLevel(zoom - 1);
        map.setZoom(zoom - 1);
      }
    } else if (trigerIcon === "plus") {
      if (zoom < 18) {
        setZoomLevel(zoom + 1);
        map.setZoom(zoom + 1);
      }
    }
  };

  const sortLocationOnDistance = (locations, filterAddress = null) => {
    let centerPoint = L.latLng(center[0], center[1]);
    let temp = [];
    locations.map((loc) => {
      let dist = position
        ? position.distanceTo({ lat: loc.latitude, lng: loc.longtitude })
        : centerPoint.distanceTo({
          lat: loc.latitude,
          lng: loc.longtitude,
        });
      temp.push({ ...loc, dist });
    });
    const seen = new Set();
    const filteredArr = temp.filter((el) => {
      const duplicate = seen.has(el.p_id);
      seen.add(el.p_id);
      return !duplicate;
    });
    temp?.sort((a, b) => a.dist - b.dist);
    setFilterAddress(temp);
    if (filterAddress) {
      // it updates redux store for filter panel
      let filterLocation = []
      temp.forEach(item => {
        filterLocation.push({ id: item.p_id, name: item.name, type: "radio", value: false, detail: item })
      })
      let filterOption = MENU_CONTENT.filter(item => {
        if (item.id === "location") {
          item.data = filterLocation
          return item;
        }
      })
      let updatedFilter = [...MENU_CONTENT]
      // dispatch(setFilters(updatedFilter))
    }
  };
  const navigateToUserLocation = (action = true) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let loc = L.latLng(
          parseFloat(position.coords.latitude),
          parseFloat(position.coords.longitude)
        );
        map.flyTo(loc, action ? map.getZoom() : 15);
        L.marker([parseFloat(position.coords.latitude), parseFloat(position.coords.longitude)], { icon: CurrentLocationIcon }).addTo(map)
        setPosition(loc);
        dispatch(setUserLocation(loc))
        map.once('load moveend', function (e) {
          console.log('action:', action);
          if(action && !new URL(window.location.href).searchParams.get('productName'))
          {
            params.id ? console.log(params.id) : getLocationData(loc)
          } 
        });
      },
      (error) => {
        console.log(error);
        switch (error.code) {
          // PERMISSION_DENIED
          case 1:
            if(action && !new URL(window.location.href).searchParams.get('productName'))
            {
              params.id ? console.log(params.id) : getLocationData()
            } 
            console.log("Permission denied");
            // onOpenModal(true);
            break;
          // POSITION_UNAVAILABLE
          case 2:
            if(action && !new URL(window.location.href).searchParams.get('productName'))
            {
              params.id ? console.log(params.id) : getLocationData()
            } 
            console.log("Permission allowed, location disabled");
            onOpenModal(true);
            break;
          // TIMEOUT
          case 3:
            // notify();
            // console.log("Permission allowed, timeout reached");
            getLocationData()
            break;
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };
  const checkAvailablilityHelper = (pos, prd = null) => {
    if (!(pos && pos.products)) return;
    let te = [];
    let productList = []
    products.length === 0 ? productList = [...prd] : productList = [...products]
    productList.map((v) => {
      pos.products.map((pr) => {
        if (
          v.name == pr.name &&
          pr.instockstatus === "In Stock" &&
          v.isActive === true
        ) {
          te.push(pr);
        }
      });
    });
    return te.length <= 0;
  };
  const directionHandler = (pos) => {
    onOpenModal(true);
  };
  const notify = () =>
    toast("An error has occurred please try again.", {
      position: "top-right",
      theme: "colored",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    const appActive = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_ACTIVE : '';
    const modeClass = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_MODE_CLASS : '';
    const lightFont = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_LIGHT_FONT : '';
    const darkFont = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_DARK_FONT : '';
    const darkBackground = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_DARK_BACKGROUND : '';
    
  return (
    <div className="App">
      <ClipLoader color={appActive} loading={loader || localLoader} css={override} size={50} />
      <MapContainer
        className="map-Div"
        center={center}
        zoom={zoom}
        maxZoom={18}
        minZoom={2}
        scrollWheelZoom={true}
        whenCreated={setMap}
      >
        <MapConsumer>
          {(map) => {
            map.locate();
            mapDragging ? map.dragging.enable() : map.dragging.disable();
            return null;
          }}
        </MapConsumer>
        <div className="left-right-wrapper">
          <div />
          <SliderForZoom
            setMapDragging={setMapDragging}
            zoom={zoom}
            setMapZoom={setMapZoom}
            plusMinusZoom={plusMinusZoom}
            navigateToUserLocation={navigateToUserLocation}
          />
        </div>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={`${process.env.REACT_APP_MAPBOX_URL}?access_token=${process.env.REACT_APP_MAPBOX_KEY}`}
        />
        <MarkerClusterGroup
          showCoverageOnHover={false}
          spiderfyDistanceMultiplier={2}
          iconCreateFunction={createClusterCustomIcon.bind(products)}
        >
          {filterResults &&
            filterResults.map((pos, index) => {
              return (
                <Marker
                  key={index}
                  position={[pos.latitude, pos.longtitude]}
                  icon={
                    pos.stockStatus === "Out of Stock"
                      ? pos.p_id === selectedLocation.p_id
                        ? selectedIconGrey
                        : createCustomIconDisabled
                      : pos.p_id === selectedLocation.p_id
                        ? selectedIconGreen
                        : createCustomIcon
                  }
                  data={{ pos, products }}
                  eventHandlers={{
                    click: (e) => {
                      e.target.closePopup();
                      setLocalLoader(true)
                      dispatch(getSingleLocationData(`longlat=${currentLocation?.lng ?? 0},${currentLocation?.lat ?? 0}&location=${encodeURIComponent(pos.name)}`, 'click'))
                    },
                  }}
                >
                  {(
                    <Popup
                      ref={el => popupRef.current[pos.p_id] = el}
                      className={"location-direction " + modeClass}
                      onClose={() => {
                        if (pos.p_id !== selectedLocation.p_id) {
                          // if (locationsRef.current[pos.p_id])
                          // locationsRef.current[pos.p_id].click();
                          // setEnableClick(false);
                          // setSelectedLocation({});
                        }
                      }}
                    >
                      <div>
                        <div className="address-section">
                          <span className="bld-name">{pos.name}</span>
                        </div>
                        <div className="distance">
                          <span>{parseFloat(pos.distance).toFixed(1) + " KM"}</span>
                        </div>
                        <div className="address-section">
                          <span className="secondary-txt" style={{ color: lightFont }}>{pos?.address + ", " + pos?.city + ", " + pos?.province}</span>
                        </div>
                        <div className="outer-div-direction">
                          {position ?
                            <a
                              className={"direction-popup-button"}
                              target={'_blank'} href={`https://www.google.com/maps/dir/${position.lat},${position.lng}/${pos.latitude},${pos.longtitude}`}
                              style={{ background: darkBackground, color: darkFont, border: "1px solid " + lightFont }}
                            >
                              Directions
                            </a>
                            : <button
                              onClick={() => directionHandler(pos)}
                              className="direction-popup-button"
                              style={{ background: darkBackground, color: darkFont, border: "1px solid " + lightFont }}
                            >
                              Directions
                            </button>}
                        </div>
                      </div>
                    </Popup>
                  )}
                </Marker>
              );
            })}
        </MarkerClusterGroup>
      </MapContainer>
      <ToastContainer />
    </div>
  );
}

export default Map;
